.gestion-scoring {
	.like-accordeon {
		border-radius: 5px;
		margin-top: 10px;
		border: solid 1px #cccccc;
		padding: 20px 20px;
		height: 60px;
		line-height: 20px;
		position: relative;
		cursor: pointer;
		@include media-breakpoint-down(md) {
			span.h2 {
				font-size: 16px;
			}
		}
		span {
			cursor: pointer;

			&::selection {
				background-color: transparent;
			}
		}

		&::after {
			content: $hi-triangle-bottom;
			@include icomoon-icon-style();
			position: absolute;
			font-size: 9.5px;
			color: $blue;
			right: 17.5px;
			top: calc(50% - 9.5px/2);
			pointer-events: none;
			@include media-breakpoint-down(md) {
				font-size: 6.5px;
				top: calc(50% - 6.5px/2);
			}
		}

		&.is-hide::after {
			content: $hi-triangle-top;
		}
	}

	.hide {
		display: none;
	}
}

.table-scoring {

	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: #e9e9e9;
	}

	&.table-striped tbody tr:nth-of-type(even) {
		background-color: $grey-bg;
	}

	td,
	th {
		border: 5px solid #FFF;
		text-align: center;

		font-weight: bold;
		height: 50px;
		padding: 0;
		vertical-align: middle;

		@include media-breakpoint-down(md) {
			min-width: 125px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 18px;
		}
	}

	th {
		width: 20%;
		height: 60px;

	}

	.row-entite td {
		color: $red;
		font-size: 16px;

		@include media-breakpoint-up(lg) {
			font-size: 21px;
		}
	}

	.row-filiale td:last-of-type {
		color: $red;
	}

	.row-etablissement td:first-of-type {
		font-size: 12px;

		@include media-breakpoint-up(lg) {
			font-size: 16px;
		}

		font-weight: 600;
	}


	.row-etablissement td,
	.row-filiale td {
		position: relative;
	}

	.row-etablissement td a.delete:after,
	.row-filiale td a.delete:after {
		@include icomoon-icon-style();
		content: $hi-trash;
		color: $black-text;
		position: absolute;
		font-size: 16px;
		right: -25px;
		top: calc(50% - 8px);
	}
}

.table-scoring.table-scoring-total {

	td,
	th {
		border: solid 1px #d2d2d2;
		height: 50px;
		font-size: 19px;

		@include media-breakpoint-up(lg) {
			height: 60px;
			font-size: 21px;
		}

		&:first-of-type,
		&:last-of-type {
			color: $red;
		}
	}
}

.scoring-info {
	h3 {
		font-size: 21px;
		font-weight: 900;
		color: $red;

		@include media-breakpoint-down(md) {
			margin-top: 12px;
		}
	}

	p {
		@include media-breakpoint-up(lg) {
			font-size: 16px;
			line-height: 21px;
		}
	}

	.text-blue {
		@include media-breakpoint-up(lg) {
			font-size: 16px;
			line-height: 21px;
		}

		font-weight: bold;

		color: $blue;

		@include media-breakpoint-up(lg) {
			padding-right: 85px;
		}
	}
}