//Custom variables

/* Color palette */

$blue: #003f7a;
$red: #e41d4b;
$black-text: #333333;
$grey-bg: #f4f4f4;
$grey-text: #999999;

