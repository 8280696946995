.demande-logement p {
	font-size: 16px;
}



.demande-logement {
	.desktop-version {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	.mobile-version {
		display: none;
		@include media-breakpoint-down(md) {
			display: block;
		}

		.form-radio , .form-check {
			display: block;
			margin-bottom: 15px;
		}
		.checkbox-inline-text .form-check {
			display: inline-flex;
		}
		div.form-radio.form-check-inline,
		div.form-check.form-check-inline {
			margin-left: 20px;
		}
	}
	.dl-navigation {
		padding-left: 30px;
		list-style-type: none;

		li {
			padding-top: 11px;
			padding-bottom: 11px;
			
			position: relative;
			font-size: 18px;
			line-height: 25px;
			@include media-breakpoint-down(lg) {
				font-size: 15px;
				line-height: 20px;
			}
			&:not(:last-of-type) {
				border-bottom: #e1e1e1 solid 1px;
			}
			a {
				color: $grey-text;
				font-weight: bold;
			}

			&:before {
				@include icomoon-icon-style();
				content: $hi-arrow-round-right;
				display: block;
				position: absolute;
				font-size: 19px;
				left: -30px;
				top: 13px;
				color: $grey-text;
			}

			&.validate {
				a {
					color: $blue;
				}

				&:before {
					color: $blue;
					content: $hi-check-round;
				}
			}

			&.error {
				a {
					color: $red;
				}

				&:before {
					color: $red;
					content: $hi-error-round;
				}
			}
		}
	}
	.dl-action-button {
		>a {
			display: block;
			background: $grey-bg;
			padding-top: 16.5px;
			padding-bottom: 16.5px;
			padding-left: 60px;
			text-align: left;
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 10px;
			border-radius: 10px;
			@include media-breakpoint-down(lg) {
				padding-left: 10px;
			}
			span {
				font-size: 37px;
				line-height: 37px;
				vertical-align: middle;
				margin-right: 10px;
				@include media-breakpoint-down(lg) {
					font-size: 25px;
					margin-right: 5px;
				}
			}
		}
	}

	.dl-form {
		@extend .global-form;
		.dl-ressources-input-list {
			&.form-group {
				margin-bottom: 13px;
				 label {
				 	font-size: 14px;
				 	margin-bottom: 0;
				 	font-weight: 600;
				 }
			}
		}

		.al-dl-accordeon + .dl-section-title {
			margin-top: 20px;
		}
		.form-group {
			margin-bottom: 30px;
			&:not(.no-paddings) {
				padding-left: 95px;
				padding-right: 95px;
				@include media-breakpoint-down(lg) {
					padding-left: 30px;
					padding-right: 30px;
				}
				@include media-breakpoint-down(sm) {
					padding-left: 10px;
					padding-right: 10px;
				}
			}
			&.padding-lo {
				padding-left: 95px;
				padding-right: 0;
				@include media-breakpoint-down(lg) {
					padding-left: 30px;
				}
				@include media-breakpoint-down(sm) {
					padding-left: 10px;
				}
			}
			span.w {
				color: $red;
			}
			.form-control.year-width {
				width: 70px;
			}
			.form-control.amount-revenue-width {
				width: 130px;
			}
			.euro-sym-input {
				position: relative;
				&::after {
					content:"€";
					display: block;
					position: absolute;
					font-size: 16px;
					top: 13px;
					right: 25px;

				}
				input {
					padding-right: 30px;
				}
				&.inline-input {
					display: inline-block;
				}
			}
			.surface-input {
				@extend .euro-sym-input;
				&::after {
					content: "m²";
				}
			} 
		}
		.pad-collapse {
			padding-top: 20px;
			.date-select {
				width: 370px;
				@include media-breakpoint-down(sm) {
					width: initial;
				}
			}
		}
		.pad-collapse .form-group {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.dl-section-title {
		font-size: 18px;
		font-weight: bold;
		line-height: 19px;
		text-align: left;
		color: $blue;
		padding: 20.5px 21px;
		background-color: $grey-bg;
		border-radius: 10px;
		margin-bottom: 30px;

		@include media-breakpoint-down(sm) {
			font-size: 16px;
			padding: 10px 10px;
			margin-top: 10px;
			border-radius: 5px;
		}

	}
	.dl-tab-title {
		font-size: 29px;
		color: #e41d4b;
		font-weight: 700;
		margin-bottom: 30px;
	}
	.dl-inside-section-title {
		font-size: 17px;
		font-weight: bold;
		line-height: 19px;
		margin-bottom: 20px;
		margin-top: 30px;
		>span {
			font-weight: 500;
		}
	}
	.dl-first-line-address {
		max-width: 550px;

		@include media-breakpoint-down(lg) {
			width: 100%;
			max-width: 100%;
			padding-right: 30px;
		}
		@include media-breakpoint-down(sm) {
			padding-right: 10px;
		}
	}

	.dl-paddings {
		padding-left: 95px;
		padding-right: 95px;

		@include media-breakpoint-down(lg) {
			padding-left: 30px;
			padding-right: 30px;
		}
		@include media-breakpoint-down(sm) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.dl-paddings-lo {
		padding-left: 95px;

		@include media-breakpoint-down(lg) {
			padding-left: 30px;
		}
		@include media-breakpoint-down(sm) {
			padding-left: 10px;
		}
	}

	.dl-paddings-ro {
		padding-right: 95px;

		@include media-breakpoint-down(lg) {
			padding-right: 30px;
		}
		@include media-breakpoint-down(sm) {
			padding-right: 10px;
		}
	}
	
}

.demande-logement {
	.al-dl-mobile-accordeon {
		margin-bottom: 5px;
		> a {
			display: block;
			border-radius: 5px;
			font-size: 17px;
			font-weight: bold;
			color: #FFF;
			background: #c6c6c6;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 10px;
			padding-bottom: 10px;
			&:before {
				content: $hi-arrow-round-right;
				@include icomoon-icon-style();
				vertical-align: middle;
				margin-right: 6px;
				
			}
			&.validate {
				background: $blue;
				&:before {
					content: $hi-check-round;
				}
			}
			&.error {
				background: $red;

				&:before {
					content: $hi-error-round;
				}
			}
		}
	}
}

.big-indications {
	font-size: 35px;
	line-height: 31px;
	text-align: center;
	color: $blue;
	font-weight: 900;
	@include media-breakpoint-down(sm) {
		font-size: 27px;
	}
	&.red {
		color: $red;
	}
	.little {
		font-size: 21px;
		color: $blue;
		font-weight: bold;
	}
}

.nextstep-indications {
	font-size: 26px;
	font-weight: bold;
	line-height: 30px;
	color: $blue;
	
	.important {
		text-decoration: underline;
		color: $red;
	}
	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: normal;
	}
}


.modal-upload {
	text-align: left;
	.mu-title {
		font-size: 23px;
		font-weight: bold;
		line-height: 17px;
		color: $blue;
		margin-bottom: 27px;
	}
	p {
		font-size: 16px;
		font-weight: 500;
		line-height: 23px;
		text-align: justify;
	}
	.al-popup {
		padding: 50px;
		@include media-breakpoint-down(sm) {
			padding: 10px;
			padding-top: 50px;
		}
	}
	.title-modal {
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
		&::before {
			@include media-breakpoint-down(sm) {
				display: block;
				text-align: center;
				margin-bottom: 10px;
			}
		}
	}

	.upload-zone {
		background-color: #e8e8e8;
		padding: 33px 0;
		margin-top: 20px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		color: $blue;
		
		@include media-breakpoint-down(sm) {
			padding: 12px;
		}

	}
	.a-file-list {
		background-color: #e8e8e8;
		padding: 19px 23px;
		font-size: 16px;
		font-weight: bold;
		color: $black-text;
		position: relative;
		padding-right: 50px;
		a {
			color: $black-text;
			&:before {
				
				position: absolute;
				right: 20px;
				vertical-align: middle;
				
			}
		}
	}
	.mu-button-close {
		position: absolute;
		top: 15px;
		right: 15px;
		
		&::before {
			@include icomoon-icon-style();
			content: $hi-cross-round;
			color: #000;
			font-size: 34px;
		}
	}
}