.bgred {
	background: red !important;
	min-height: 1px;

	>* {
		background: purple !important;
		min-height: 1px;

		>* {
			background: yellow !important;
			min-height: 1px;
		}
	}
}

.color-red {
	color: $red;
}

.color-blue {
	color: $blue;
}

.bgc-red {
	background-color: $red;
}

.bgc-blue {
	background-color: $blue;
}

.checkbox-align-form {
	height: 45px;
	display: flex;
	label {
		margin-bottom: 0;
	}
}

.separator-m {
	width: 100%;
	height: 1px;
	background-color: #e1e1e1;
}

.separator-mb {
	width: 100%;
	height: 1px;
	margin-top: 31px;
	background-color: #e1e1e1;
}

.separator-mt {
	@extend .separator-m;
	margin-bottom: 35px;
}

.separator {
	@extend .separator-mb;
	margin-bottom: 35px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb20 {
	margin-bottom: 20px;
}

.ml20 {
	margin-left: 20px;
}
.mr20 {
	margin-right: 20px;
}

.mr30 {
	margin-right: 30px;
}

.ml30 {
	margin-left: 30px;
}

.mb30 {
	margin-bottom: 30px;
}
.mb30i {
	margin-bottom: 30px !important;
}

.mb40 {
	margin-bottom: 50px;
}

.mb50 {
	margin-bottom: 50px;
}

.mt20 {
	margin-top: 20px;
}

.mt10 {
	margin-top: 10px;
}

.mt30 {
	margin-top: 30px;
}

.mt40 {
	margin-top: 40px;
}



.gutters-10 {
	margin-right: -5px;
	margin-left: -5px;

	>.col,
	>[class*="col-"] {
		padding-right: 5px;
		padding-left: 5px;
	}
}

.gutters-14 {
	margin-right: -7px;
	margin-left: -7px;

	>.col,
	>[class*="col-"] {
		padding-right: 7px;
		padding-left: 7px;
	}
}

.gutters-18 {
	margin-right: -9px;
	margin-left: -9px;

	>.col,
	>[class*="col-"] {
		padding-right: 9px;
		padding-left: 9px;
	}
}

.text-16 {
	font-size: 16px;
	line-height: 21px;
}

.gutters-20 {
	margin-right: -10px;
	margin-left: -10px;

	>.col,
	>[class*="col-"] {
		padding-right: 10px;
		padding-left: 10px;
	}
}
@include media-breakpoint-down(xl) {
	.gutters-20-tablet-down {
		margin-right: -10px;
		margin-left: -10px;

		>.col,
		>[class*="col-"] {
			padding-right: 10px;
			padding-left: 10px;
		}
	}
}

.gutters-25 {
	margin-right: -12.5px;
	margin-left: -12.5px;

	>.col,
	>[class*="col-"] {
		padding-right: 12.5px;
		padding-left: 12.5px;
	}
}

@include media-breakpoint-up(xl) {
	.gutters-50 {
		margin-right: -25px;
		margin-left: -25px;

		>.col,
		>[class*="col-"] {
			padding-right: 25px;
			padding-left: 25px;
		}
	}
	.gutters-70 {
		margin-right: -35px;
		margin-left: -35px;

		>.col,
		>[class*="col-"] {
			padding-right: 35px;
			padding-left: 35px;
		}
	}
}

.title-w-tool {
	margin-right: 20px;

	@include media-breakpoint-down(md) {
		display: block;
		margin-bottom: 4px;
	}
}

a.tool-link {
	&:hover {
		color: $black-text;
		text-decoration: none;
	}

	font-weight: 500;
	text-decoration: underline;
	color: $black-text;
	margin-right: 10px;
	&.active {
		text-decoration: none;
	}
	&:before {
		@include icomoon-icon-style();
		vertical-align: baseline;
		display: inline-block;
		margin-right: 4px;
	}
}

@include media-breakpoint-down(sm) {
	.mt10-mo {
		margin-top: 10px;
	}

	.mb10-mo {
		margin-bottom: 10px;
	}

	.mt20-mo {
		margin-top: 20px;
	}

	.mb20-mo {
		margin-bottom: 20px;
	}

	.mb30-mo {
		margin-bottom: 30px;
	}

}

@include media-breakpoint-down(md) {
	.mt10-md-d {
		margin-top: 10px;
	}

	.mb10-md-d {
		margin-bottom: 10px;
	}

	.mt20-md-d {
		margin-top: 20px;
	}

	.mb20-md-d {
		margin-bottom: 20px;
	}

	.mt40-md-d {
		margin-top: 40px;
	}

	.mb40-md-d {
		margin-bottom: 40px;
	}
}

@include media-breakpoint-up(md) {

	.mr20-md-u {
		margin-right: 20px;
	}
}


@include media-breakpoint-down(xs) {
	.mt10-xs-d {
		margin-top: 10px;
	}

	.mb10-xs-d {
		margin-bottom: 10px;
	}

	.mt20-xs-d {
		margin-top: 20px;
	}

	.mb20-xs-d {
		margin-bottom: 20px;
	}

	.mt40-xs-d {
		margin-top: 40px;
	}

	.mb40-xs-d {
		margin-bottom: 40px;
	}
}