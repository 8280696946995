/* Base Styles -------------------- */

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: 'proxima-nova', sans-serif;
	color: $black-text;
	font-size: 14px;
	line-height: initial;
}

h1,
.h1 {
	font-size: 31px;
	font-weight: bold;
	text-align: left;
	color: $red;
	letter-spacing: normal;
	margin-bottom: 20px;
}

h3,
.h3 {
	font-size: 21px;
	font-weight: bold;
	;
	letter-spacing: normal;
	text-align: left;
	color: $blue;
	margin-bottom: 15px;
}

.title-black {
	color: #000000 !important;
}

h2,
.h2 {
	font-size: 26px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 17px;
	letter-spacing: normal;
	margin-bottom: 15px;
	color: $blue;

}

@include media-breakpoint-down(md) {

	h2,
	.h2 {
		font-size: 24px;
		line-height: normal;
	}
}

h4,
.h4 {
	font-size: 20px;
	color: $black-text;
	font-weight: bold;
}

.tools-btn {
	font-size: 13px;
	padding: 5px 12px;
	border: solid 2px $blue;
	border-radius: 5px;
	font-weight: bold;
	color: $blue;
	display: inline-block;

	&:hover {
		color: $blue;
	}

	img {
		height: 16px;
		margin-right: 8.5px;
		vertical-align: sub;
	}

	&:before {
		vertical-align: middle;
		font-size: 16px;
		margin-right: 7px;
	}
}

.global-form {
	.form-group {
		margin-bottom: 16px;

		label {
			font-size: 16px;
			font-weight: 500;
		}
		.indications {
			display: inline-block;
			margin-top: 5px;
		}

		.form-control {
			border-color: #ccc;
			color: $grey-text;
			border-radius: 5px;
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			font-weight: 500;
			&.inline-input {
				display: inline-block;
			}
		}
		&.date-select {
			max-width: 560px;
			label {
				display: block;
			}
			.select-wrapper {
				display: inline-block;
			}
		}
		.select-wrapper {
			position: relative;

			select {
				padding-right: 25px;
			}
		}

		.select-wrapper::after {
			content: $hi-triangle-bottom;
			@include icomoon-icon-style();
			position: absolute;
			font-size: 6.5px;
			color: $blue;
			right: 13px;
			top: calc(50% - 6.5px/2);
			pointer-events: none;
		}

		.mini-select-wrapper {
			position: relative;

			select.form-control {
				font-size: 12px;
				color: $black-text;
				height: 26px;
				padding-right: 20px;
				line-height: initial;
				border: 1px solid $black-text;
				border-radius: 0;
				display: inline-block;
				font-weight: 600;
			}
		}

		.mini-select-wrapper::after {
			content: $hi-mini-selector;
			@include icomoon-icon-style();
			position: absolute;
			font-size: 10.5px;
			color: $black-text;
			right: 8px;
			top: calc(50% - 10.5px/2);
			pointer-events: none;
		}

		&.with-tags .input-with-tags {
			position: relative;
		}

		&.with-tags .input-with-tags:after {
			content: $hi-triangle-right;
			@include icomoon-icon-style();
			position: absolute;
			font-size: 12px;
			color: $blue;
			right: 14px;
			top: calc(50% - 12px/2);
			pointer-events: none;
		}

		.input-with-calendar {
			position: relative;

			input {
				padding-right: 36px;
			}

			>a img {
				height: 27px;
				display: inline-block;
				position: absolute;
				top: 8.5px;
				right: 8.5px;

			}
		}
		&.checkbox-inline-text {
			> label {
				display: inline;
			}
			.checkbox-align-form {
				display: inline;
			}
			span.checkmark {
				top: -13px;
				left: 4px;
			}
		}
	}



	.form-check {
		&.no-text {
			padding-left: 0;
		}

		.checkbox {
			display: block;
			position: relative;
			padding-left: 25.5px;
			cursor: pointer;
			font-size: 16px;
			line-height: 19px;
			color: $blue;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;
			}

			&:hover input~.checkmark {
				background-color: $grey-bg;
			}

			input:checked~.checkmark {
				background-color: $blue;
			}

			input:checked~.checkmark:after {
				display: block;
			}

			.checkmark:after {
				font-family: '#{$icomoon-font-family}';
				content: $hi-checkbox-check;
				font-size: 8.5px;
				color: #FFF;
				text-align: center;
				width: 100%;
				height: 100%;
				line-height: 13px;
			}
		}

		/* Create a custom checkbox */
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 17px;
			width: 17px;
			border: solid 2px $blue;
			border-radius: 3px;
			background-color: transparent;
		}


		/* Create the checkmark/indicator (hidden when not checked) */
		.checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}

		&.form-check-black {
			.checkbox {
				color: $black-text;

			}

			.checkmark {
				border-color: $black-text;
			}

			.checkmark:after {
				background: $black-text;
			}
		}
	}



	.form-radio {
		.radio {
			display: block;
			position: relative;
			padding-left: 35px;
			cursor: pointer;
			padding-left: 25.5px;
			color: $blue;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			margin-bottom: 0;
			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
			}

			&:hover input~.checkmark {
				background-color: transparent;
			}

			input:checked~.checkmark {

				background-color: transparent;
			}

			input:checked~.checkmark:after {
				display: block;
			}

			.checkmark:after {
				top: calc(50% - 4px);
				left: calc(50% - 4px);
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: $blue;
			}
		}

		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 16px;
			width: 16px;
			background-color: transparent;
			border: solid 2px $blue;
			border-radius: 100%;
		}

		.checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}

		&.form-radio-black {
			.radio {
				color: $black-text;

			}

			.checkmark {
				border-color: $black-text;
			}

			.checkmark:after {
				background: $black-text;
			}
		}
	}

}


.al-accordeon>a {
	border-radius: 5px;
	display: block;
	width: 100%;
	margin-top: 10px;
	border: solid 1px #cccccc;
	padding: 20px 20px;
	padding-right: 50px;
	min-height: 60px;
	position: relative;
	cursor: pointer;

	font-size: 26px;
	font-weight: bold;
	text-align: left;
	color: $blue;



	@include media-breakpoint-down(md) {
		font-size: 21px;
	}

	span {
		cursor: pointer;

		&::selection {
			background-color: transparent;
		}
	}

	&::after {
		content: $hi-triangle-bottom;
		@include icomoon-icon-style();
		position: absolute;
		font-size: 9.5px;
		color: $blue;
		right: 17.5px;
		top: calc(50% - 6.5px/2);
		pointer-events: none;

		@include media-breakpoint-down(md) {
			font-size: 6.5px;
			top: calc(50% - 6.5px/2);
		}
	}

	&.collapsed {
		color: $grey-text;
	}

	&.collapsed::after {
		content: $hi-triangle-right;
		color: $grey-text;
		font-size: 19px;
		top: calc(50% - 13px/2);
		right: 22px;

		@include media-breakpoint-down(md) {
			font-size: 13px;
			top: calc(50% - 13px/2);
		}
	}
	
}
.al-accordeon.al-dl-accordeon {
	>a {
		font-size: 17px;
		font-weight: bold;
		min-height: auto;
		padding-top: 13px;
		padding-bottom: 13px;
	}
	>div {
		border-bottom: 1px solid #e0e0e0;
	}
}

a:hover {
	text-decoration: none;
}

.form-control {
	height: calc(1.5em + 0.75rem + 9px);
}

.container-small {
	@extend .container;

	@include media-breakpoint-up(lg) {
		width: 760px;
	}
}

.back-search a {
	font-size: 13px;
	color: #666666;
	font-weight: 600;

	&:before {
		font-size: 14.5px;
		margin-right: 5.5px;
		vertical-align: text-top;
	}
}

.btn-full-width {
	width: 100%;
}

.btn {
	font-weight: bold;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 15.5px;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 5px;
	border-width: 2px;
}
.btn.padding-light {
	    padding-left: 15px;
	    padding-right: 15px;
}
.btn[class*="btn-outline-"],
.btn[class*="btn-outline-"]:hover {
	border-width: 2px;
}

.btn-13 {
	font-size: 13px;
}

.btn[class*="hi-"] {
	padding-left: 17px;
	padding-right: 22.5px;

	&:before {
		font-size: 20px;
		vertical-align: middle;
		margin-right: 8px;
	}
}

.btn-primary {
	@include button-variant($blue, $blue);
}

.btn-secondary {
	@include button-variant($red, $red);
}

.btn-dark {
	@include button-variant(#323232, #323232);
}

.btn-outline-primary {
	@include button-outline-variant($blue, #fff);
}

.btn-outline-secondary {
	@include button-outline-variant($red, #fff);
}

.btn-outline-dark {
	@include button-outline-variant(#323232, #fff);
}

@include media-breakpoint-down(sm) {
	body {
		font-size: 12px;
	}
}

@include media-breakpoint-down(md) {

	h3,
	.h3 {
		font-size: 19px;
	}

	.h4,
	h4 {
		font-size: 17px;
	}
}



.loader-block {
	margin-left: auto;
	margin-right: auto;
}

.loader .modal-backdrop {
	background: #FFF;
}

// fix probleme
.entreprise-filters .form-check .checkbox {
	font-weight: bold;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: #f8f9fa;
}