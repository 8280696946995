body.tdb-entreprise {
	background-color: $grey-bg;

	header {
		background-color: #fff;
	}

	.citybox .citybox-inner {
		>.select-date {
			height: 96px;
			border-bottom: 1px solid #cccccc;
			padding-left: 12.5px;
			padding-right: 12.5px;

			.form-group {
				@include media-breakpoint-down(md) {
					margin-bottom: 7px;
				}
			}

			padding-top: 7px;

			label {
				font-size: 16px;
				font-weight: 500;

				@include media-breakpoint-up(md) {
					margin-right: 10px;
				}

				@include media-breakpoint-up(xs) {
					margin-right: 0;
				}
			}

			.form-control {
				display: inline-block;
				padding-right: 35px;
			}

			a img {
				width: 21px;
				display: inline-block;
				position: absolute;
				bottom: 17px;
				right: 15px;

				@include media-breakpoint-up(lg) {
					bottom: 26px;
				}
			}

			@include media-breakpoint-down(md) {
				height: auto;
			}
		}
	}

	.logement-count>div {
		border-top: 1px solid #CCC;
		border-bottom: 1px solid #CCC;
		font-size: 21px;
		color: $blue;
		font-weight: 700;

		.number {
			font-weight: bold;
			color: $red;
			font-size: 45px;
			vertical-align: sub;
			margin-right: 5px;
			line-height: 70px;

			@include media-breakpoint-down(sm) {
				line-height: normal;
			}
		}

		.bold {
			font-weight: 900;
		}
	}

	@include media-breakpoint-down(xs) {
		.logement-count {
			margin-left: 20px;
			margin-right: 20px;

			>div {
				font-size: 19px;
			}

			.number {
				display: block;
				margin-right: 0;
				line-height: 55px;
			}
		}
	}



	.section-scoring,
	.section-priorite {
		text-align: center;

		img {
			display: block;
			margin: 0 auto;
		}

		.title {
			font-size: 27px;
			color: $blue;
			font-weight: 900;
			display: block;
			line-height: normal;
		}
	}

	.section-scoring {
		border-bottom: 1px solid #ccc;

		img {
			margin-bottom: 25.5px;
		}

		.title {
			margin-bottom: 23px;
		}

		.btn {
			margin-bottom: 50px;

			@include media-breakpoint-down(md) {
				margin-bottom: 0px;
			}
		}

		@include media-breakpoint-down(md) {
			border-bottom: 0;

			&:after {
				content: none;
			}
		}
	}

	.section-priorite {

		img {
			margin-bottom: 17.5px;
		}

		.title {
			margin-bottom: 24px;
		}

		.bignumber {
			display: block;
			font-size: 55px;
			font-weight: 900;
			color: $red;
			line-height: 41px;
			margin-bottom: 33px;
		}

		@include media-breakpoint-down(md) {
			.btn {
				margin-bottom: 20px;
			}

		}

		@include media-breakpoint-up(lg) {
			margin-top: 25px;
		}

	}
}

body.tdb-bailleur {
	@extend .tdb-entreprise;

	.citybox {
		.citybox-inner {
			.skyline {
				height: unset;
				min-height: 110px;

				.kpi {
					background: unset;
					text-align: center;
					border-bottom: none;
					padding-top: 18px;
					padding-bottom: 12px;
					margin-bottom: 0;

					>div,
					>img {
						display: inline-block;
						vertical-align: middle;
					}

					>img {
						max-height: 79px;
						margin-right: 35px;
					}

					.number {
						margin-right: 10px;
					}
				}
			}
		}
	}

	.tdb-b-list-affect-logement {
		margin-bottom: 20px;

		.list-people {
			padding-bottom: 0;
			margin-bottom: 0;

			@include media-breakpoint-down(sm) {
				padding-left: 10px;

				li {
					line-height: normal;
					margin-bottom: 15px;
				}
			}

		}

		.bordered-lo {
			border-left: 1px solid #e1e1e1;

			@include media-breakpoint-down(sm) {
				border: none;
			}
		}
	}

	.tdb-b-espace-doc {
		.h2 {
			margin-bottom: 38px;
		}

		img {
			margin-bottom: 43px;
		}
	}

	.inner-whitebox .whitebox {
		padding-top: 45px;
		height: 100%;
	}

	.tdb-b-arrow-down {
		text-align: center;

		img {
			height: 21px;
		}
	}

	.tdb-b-count-offer {
		margin-bottom: 35px;

		>div {
			border-top: 1px solid #CCC;
			border-bottom: 1px solid #CCC;
			font-size: 21px;
			color: $blue;
			font-weight: 700;

			@include media-breakpoint-down(sm) {
				padding-bottom: 7px;
			}

			.number {
				font-weight: bold;
				color: $red;
				font-size: 45px;
				vertical-align: sub;
				margin-right: 5px;
				line-height: 70px;

				@include media-breakpoint-down(sm) {
					line-height: normal;
				}
			}

			.bold {
				font-weight: 900;
			}
		}
	}
}

.tdb-b-table-flux {
	width: 100%;
	text-align: center;
	font-weight: bold;
	color: $blue;
	border: 1px solid #ccc;
	margin-top: 30px;

	th {
		font-size: 16px;
	}

	tr {
		font-size: 22px;

		.small {
			font-size: 14px;
			font-weight: bold;
		}
	}

	th,
	td {
		padding-top: 8px;
		padding-bottom: 8px;
		border: 1px solid #ccc;
		line-height: 23px;
	}

	.orange {
		color: #f07d00;
	}
}

.suivi-flux .tdb-b-table-flux {
	th {
		@include media-breakpoint-up(md) {
			width: 33.3%;
		}

	}
}

.affectation-logements .col-button {
	@include media-breakpoint-up(xl) {
		max-width: 115px;
	}
}