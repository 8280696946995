body.home {
	&:not(.home-offres) {
		background: $grey-bg;

		header {
			background: #fff;
			margin-bottom: 0;
		}
	}

	.header-btn {
		background-color: $red;
		border-color: $red;

		@media (max-width: 1023px) {
			display: none;
		}
	}

	.home-link-list-mobile {
		display: block;

		@media (min-width: 1023px) {
			display: none;
		}
	}

	.navbar-brand img {
		@include media-breakpoint-down(sm) {
			max-width: 250px;
			max-height: unset;
			height: unset;
		}
	}

	.home-stripe-al {
		padding: 33px 0;
		background-color: #fff;

		img {
			height: 58px;
			vertical-align: middle;
			margin-right: 24px;

			@include media-breakpoint-down(sm) {
				display: block;
				margin-right: 0;
				margin: 0 auto;
				margin-bottom: 10px;
			}
		}

		font-size: 24px;
		font-weight: bold;
		text-align: center;
		color: $blue;
	}

	.home-pre-footer {
		padding-top: 30px;

		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		.h3 {
			font-size: 18px;
			font-weight: bold;
			color: #000;

			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}

		ul {
			list-style: none;
			padding-left: 0;

			li {
				font-weight: 600;
				line-height: 23px;

				a {
					color: #000;
				}
			}
		}
	}

	.home-block-search {
		padding: 50px;
		background-color: rgba($color: #FFFFFF, $alpha: 0.5);
		border-radius: 10px;
		margin-bottom: 50px;

		@include media-breakpoint-down(md) {
			padding: 30px;
		}

		.form-group {
			input {
				height: 51px;
				font-size: 19px;
				font-weight: 500;
			}
		}

		.home-desc-al {
			font-size: 21px;
			font-weight: 600;
			text-decoration: underline;

			&::before {
				line-height: 40px;
				font-size: 40px;
				vertical-align: middle;
				text-decoration: none;
				display: inline-block;
				margin-right: 10px;
			}
		}
	}

	.home-main-view {
		min-height: 730px;
		background-image: url(../img/tetiere_home.jpg);
		background-size: cover;
		background-position: center;

		.first-row {
			min-height: 730px;
			position: relative;
		}

		@include media-breakpoint-down(sm) {
			min-height: 460px;

			.first-row {
				min-height: 460px;
			}
		}
	}

	.home-text-tetiere {
		font-size: 36px;
		font-weight: bold;
		text-align: center;
		color: #ffffff;

		position: absolute;
		top: 230px;
		right: 20px;

		@include media-breakpoint-down(lg) {
			font-size: 32px;
			right: -10px;
		}

		@include media-breakpoint-down(md) {
			top: 260px;
			font-size: 32px;
			right: unset;
		}

		@include media-breakpoint-down(sm) {
			top: 13px;
			left: 10px;
			font-size: 23px;
		}
	}

	header {
		box-shadow: 0 0 35px 0 rgba($color: #000000, $alpha: 0.2);
		min-width: 130px;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		left: 13px;
		border: none;

		.dropdown-item {
			font-size: 13px;
			font-weight: bold;
			text-align: left;
			padding-left: 14px;
			padding-right: 14px;
			padding-top: 7px;
			padding-bottom: 7px;
			color: $black-text;

			&.highlight {
				color: $red;
				text-decoration: underline;

			}

			&.big {
				font-size: 20px;
			}
		}
	}

	.content-nav {
		margin-bottom: 0;
		margin-top: 0;
	}

	.home-link-list-mobile .dropdown-item {
		margin-bottom: 0;
		margin-top: 0;
		font-size: 17px;
	}

	.navbar-light .navbar-toggler {
		background: $red;
		border: none;
		color: #FFF;
		font-size: 30px;
		line-height: 25px;
	}
}

body.home-offres {
	.tdb-s-block-filters {
		padding-bottom: 0;
	}
}