.mini-logement {
	&.jauge-orange a .mini-img .triangle:before {
		color: #f07d00;
	}

	&.jauge-rouge a .mini-img .triangle:before {
		color: $red;
	}

	&.jauge-vert a .mini-img .triangle:before {
		color: #7bba3e;
	}

	a {
		color: $black-text;
		font-weight: 500;
		line-height: 18px;
	}
	span.cdc {
		background: #FFF;
		color: #a7a9ac;
		text-align: center;
		line-height: 26px;
		font-size: 11px;
		display: block;
		position: absolute;
		width: 26px;
		height: 26px;
		border-radius: 3px;
		top: 7px;
		right: 7px;
		&:before {
			content: $hi-cdc;
			font-family: $icomoon-font-family;
		}

		&.active {
			background: $red;
			color: #FFF;
		}
	}
	.mini-img {
		overflow: hidden;
		position: relative;

		.overlay-dispo {
			position: absolute;
			font-weight: bold;
			width: 100%;
			height: 100%;
			font-size: 11px;
			line-height: 15px;
			text-align: center;
			border-radius: 5px;
			color: #FFF;
			background-color: rgba(0, 0, 0, 0.6);

			.content {
				margin-top: 33px;

				@include media-breakpoint-down(sm) {
					margin-top: 20px;
				}
			}

			span[class*=hi-] {
				display: block;
				margin: 0 auto;
				font-size: 27px;
				margin-bottom: 4px;
			}
		}

		.triangle:before {
			position: absolute;
			top: -0.6px;
			left: -0.6px;
			z-index: 9;
			font-size: 36px;
			text-shadow: 0px 11px 0px #FFF,
				11px 0px 0px #FFF;
		}

		.star:after {
			@include icomoon-icon-style();
			content: $hi-line-star;
			position: absolute;
			top: 4px;
			left: 4px;
			z-index: 10;
			font-size: 13px;
			color: #FFF;
		}

		img {
			border-radius: 5px;
			width: 100%;
		}
	}

	.price {
		font-weight: 900;
		font-size: 18px;
		line-height: 18px;
		color: $red;
	}

	.hi-people {
		font-weight: bold;

		&:before {
			font-size: 15px;
			vertical-align: top;
			margin-right: 4px;
		}
	}

	.city {
		font-weight: bold;
		color: $red;
		margin-top: 2px;
	}

	.ml-jauge {
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		font-size: 27px;
		@include media-breakpoint-down(sm) {
			font-size: 19px;
		}
		.pos-icon {
			color: $blue;
			display: none;

			&:before {
				line-height: 36px;
			}

			&.active {
				display: block;
			}
		}
	}
}

.whitebox {
	background-color: #fff;
	padding: 30px;
	padding-top: 40px;
	@include media-breakpoint-down(md) {
		padding: 20px;
		padding-top: 25px;
	}
}

.contacts {
	font-size: 16px;
	line-height: 21px;
	font-weight: 500;

	.bordered {
		border-right: 1px solid #e1e1e1;
		border-left: 1px solid #e1e1e1;
		&.bordered-ro {
			border-left: none;
		}
		@include media-breakpoint-down(md) {
			border: none;
		}
	}

	.name {
		font-size: 18px;
		font-weight: bold;
		color: $red;
		margin-bottom: 5px;
	}

	.title {
		font-weight: bold;
		line-height: 26px;
		color: $blue;
	}

	.acontact {
		padding-left: 70px;

		@include media-breakpoint-down(lg) {
			padding-left: 15px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 20px;
			padding-left: 0;
		}

		[class*="hi-"]::before {
			font-size: 15px;
			color: $blue;
			margin-right: 6px;
		}
	}

}

.citybox {
	text-align: center;

	>.btn {
		margin-top: 17px;

		@include media-breakpoint-down(md) {
			margin-top: 7px;
		}
	}
	ul.list-people {
		list-style-type: none;
		padding-bottom: 15px;
		margin-bottom: 16px;

		@include media-breakpoint-down(md) {
			margin-bottom: 5px;
		}

		li,
		li a {
			line-height: 28px;
			font-weight: bold;
			position: relative;
			color: $black-text;

			&.active {
				&::before {
					content: " ";
					display: block;
					width: 27px;
					height: 28px;
					left: -32.5px;
					background-image: url(../img/medaille.png);
					background-size: cover;
					position: absolute;
				}
			}
		}
	}
	.citybox-inner {
		background-color: $grey-bg;
		text-align: left;

		.skyline {
			height: 96px;
			text-align: center;
			background-image: url(../img/bg_citybox.png);
			background-repeat: no-repeat;
			background-size: auto 96px;
			border-bottom: 1px solid #cccccc;

			&.angle-1 {
				background-position: 0% bottom;
			}

			&.angle-2 {
				background-position: 25% bottom;
			}

			&.angle-3 {
				background-position: 50% bottom;
			}

			&.angle-4 {
				background-position: 75% bottom;
			}

			img {
				height: 100%;
				margin: 0 auto;
			}
		}

		.kpi-mini {
			background: #ffffff;
			border-bottom: 0;
			padding-top: 30.5px;
			padding-bottom: 15px;

			@include media-breakpoint-down(lg) {
				padding-top: 20px;
			}

			.number {
				text-align: center;
				font-size: 60px;
				color: $red;
				font-weight: bold;
				line-height: 42px;

				@include media-breakpoint-down(lg) {
					font-size: 50px;
					line-height: 38px;
				}
			}

			.text {
				font-size: 21px;
				line-height: 24px;
				font-weight: 900;
				color: $blue;
				padding-left: 5px;

				@include media-breakpoint-down(lg) {
					font-size: 19px;
					line-height: 22px;
				}

				@include media-breakpoint-down(md) {
					text-align: center;
					margin-top: 10px;
				}
			}
		}

		.kpi {
			background: #ffffff;
			padding-top: 27px;
			padding-bottom: 27px;
			border-bottom: 1px solid #cccccc;
			position: relative;
			margin-bottom: 13px;

			&.triangle-shadow {
				margin-bottom: 32.5px;
			}

			.number {
				font-size: 83px;
				color: $red;
				font-weight: bold;
				text-align: center;
				line-height: 57.5px;

				@include media-breakpoint-down(lg) {
					font-size: 70px;
				}
			}

			.text {
				font-size: 22px;
				color: $blue;
				font-weight: 900;
				line-height: 25px;
				padding-left: 5px;

				@include media-breakpoint-down(lg) {
					font-size: 20px;
					line-height: 24px;
				}

				>span {
					color: $red;
				}
			}

		}

		
	}
}

.triangle-shadow {
	margin-bottom: 19.5px;
	position: relative;

	&:after {
		content: " ";
		display: block;
		position: absolute;
		bottom: -19.5px;
		background-image: url(../img/shadow-triangle.svg);
		background-size: 100% 19.5px;
		height: 19.5px;
		width: 100%;
		left: 0;
	}
}

.entreprise-filters {
	a.ok-button {
		width: 100%;
		line-height: 45px;
		background: $blue;
		display: block;
		text-align: center;
		color: #fff;
		font-size: 13px;
		font-weight: bold;
		border-radius: 5px;
		text-transform: uppercase;

		&.align {
			margin-top: 5px;

			@include media-breakpoint-up(lg) {
				margin-top: 28px;
			}
		}
		&.align-md {
			@include media-breakpoint-up(md) {
				margin-top: 28px;
			}
		}
	}

	.wildcard {
		font-weight: 500;
		font-size: 13px;
	}
}

.atag {
	border-radius: 5px;
	background-color: #afafaf;
	color: #FFF;
	font-size: 13px;
	padding: 9.5px 9px;
	display: inline-block;
	line-height: 14.5px;
	font-weight: 600;
	margin-left: 5px;

	&:first-of-type {
		margin-left: 0;
	}

	&:before {
		@include icomoon-icon-style();
		content: $hi-cross-round;
		margin-right: 5px;
		vertical-align: top;
	}

	&:hover {
		color: #FFF;
	}
}

.overflow-scroll {
	overflow: scroll
}

.overflow-scroll-sm {
	@include media-breakpoint-down(md) {
		overflow: scroll
	}
}



table.al-table {
	text-align: center;
	font-size: 13px;
	font-weight: 600;

	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: $grey-bg;
	}

	&.table-striped tbody tr:nth-of-type(even) {
		background-color: #e9e9e9;
	}

	&.normal {
		thead {
			tr {

				th,
				td {
					height: initial;
				}
			}
		}

		tr {

			th {
				background-color: #003f7a;
				color: #fff;
			}

			td,
			th {
				height: initial;
			}
		}
	}
		&.small {
			thead {
				tr {

					th,
					td {
						height: unset;
						padding-top: 5px;
						padding-bottom: 5px;
					}
				}
			}

			tr {
				padding-top: 5px;
				padding-bottom: 5px;
				th {
					padding-top: 5px;
					padding-bottom: 5px;
					background-color: #003f7a;
					color: #fff;
				}

				td,
				th {
					padding-top: 5px;
					padding-bottom: 5px;
					height: unset;
				}
			}
		}


	thead {
		background-color: $blue;
		color: #fff;

		tr {

			th,
			td {
				height: 96px;
				vertical-align: middle;
				padding-left: 13.5px;
				padding-right: 13.5px;
				line-height: 16px;

				.text-head {
					width: 100%;
					display: table;

					>div {
						height: 48px;
						display: table-cell;
						width: 100%;
						vertical-align: middle;
						text-align: center;
					}

				}
			}
		}
	}

	tr {
		.orange {
			color: #f07d00;
		}
		th,
		td {
			border: 5px #FFF solid;
			min-width: 125px;
			height: 105px;
			vertical-align: middle;
			padding-left: 18.5px;
			padding-right: 18.5px;
			
			.number-point {
				color: $red;
				font-size: 14px;
				font-weight: bold;
				display: block;
			}
		}
	}


	.form-check:not(.form-check-score) {
		text-align: center;

		.checkmark {
			left: calc(50% - 9.5px);
			top: calc(50% - 9.5px);
		}
	}
	.select-wrapper {
		padding-left: 10px;
		padding-right: 10px;
		select {
			height: 25px;
			font-size: 10px;
			color: $black-text;
			font-weight: 600;
		}
	}	
	.form-check-score {
		padding: 4px;
		padding-right: 7px;
		background: #FFF;
		border-radius: 5px;
		border: solid 1px #cccccc;
		margin-top: 4.5px;

		&:first-of-type {
			margin-top: 0;
		}

		label.checkbox {
			font-size: 10px;
			font-weight: 600;
			margin-bottom: 0;
			padding-left: 16px;
			line-height: 14px;
			text-align: left;
			color: $black-text;

			&:hover input~.checkmark {
				background-color: $grey-bg;
			}

			input:checked~.checkmark {
				background-color: #FFF;
			}

			.checkmark:after {
				font-size: 6px;
				color: $black-text;
				line-height: 13px;
			}
		}

		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 14px;
			width: 14px;
			border-radius: 3px;
			border: solid 1px #bfbfbf;
			background-color: #fff;
		}
	}

	.sort {
		font-size: 15px;
		color: #FFF;
		margin-top: 9px;
		display: block;

		&.active {
			color: #00a0e0;
		}
	}
}

.table-pagination {
	text-align: center;

	ul {
		padding-left: 0;

		li {
			font-size: 16px;
			display: inline;
			margin: 0 3px;

			a {
				color: #9a9a9a;
				text-decoration: underline;
				font-weight: 600;
			}

			&.current a {
				text-decoration: none;
				color: $blue;
			}
		}
	}
}

.itwt {
	background: #FFF;
	border: 1px solid #cccccc;
	border-radius: 5px;
	padding: 7px;
	min-height: 45px;

	.itwt-tags {
		margin-left: -5px;
		margin-top: -5px;
	}

	.atag {
		margin-right: 0;
		margin-left: 5px;
		margin-top: 5px;

		&:last-of-type {
			margin-bottom: 6px;
		}
	}

	input {
		font-size: 16px;
		font-weight: 500;
		color: $grey-text;
		border: none;
		padding-top: 10px;
		padding-bottom: 5px;
		max-height: 29px;
		width: 100%;

		&:focus {
			outline: none;
		}
	}

	&.inline-mode {
		padding: 5px;

		.itwt-tags {
			display: inline-block;

			.atag:last-of-type {
				margin-bottom: auto;
			}
		}

		.itwt-input {
			display: inline-block;
		}
	}
}

.title-img-red {
	height: 100px;
	font-size: 29px;
	font-weight: 900;
	color: #FFF;
	line-height: 100px;
	background-size: cover;
	background-image: url(../img/entreprise/title-img-red.jpg);

	@include media-breakpoint-down(md) {
		font-size: 18px;
		margin-bottom: 20px;
	}
}

.al-modal-size {
	@include media-breakpoint-up(lg) {
		max-width: 600px;
	}
}

.al-popup {
	padding: 35px;
	border: none;

	.icon-modal {
		font-size: 35px;
		color: $red;
	}

	.title-modal {
		font-size: 23px;
		color: $red;
		font-weight: bold;
		&:before {
			font-size: 35px;
			margin-right: 15px;
			vertical-align: middle;
		}
	}

	.content-modal {
		font-size: 16px;
	}

	@include media-breakpoint-down(xs) {
		.btn {
			width: 100%;
		}
	}
}

.block-info-d {
	border-radius: 10px;
	background-color: $grey-bg;
	overflow: hidden;

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	color: $black-text;

	.bid-header {
		&.red {
			background-color: $red;
		}

		&.blue {
			background-color: $blue;
		}

		padding-left: 32px;
		padding-right: 64px;
		padding-top: 18px;
		padding-bottom: 18px;
		font-size: 23px;
		font-weight: bold;
		text-align: left;
		color: #ffffff;
		position: relative;

		@include media-breakpoint-down(sm) {
			padding-left: 15px;
		}

		a.modify-data {
			&::after {
				color: #FFF;
			}
		}
	}

	ul.data-list {
		list-style-type: none;
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 32px;
		padding-bottom: 22px;

		@include media-breakpoint-down(sm) {
			padding-left: 15px;
			padding-right: 15px;
		}

		>li {
			margin-top: 20px;

			&:first-of-type {
				margin-top: 0;
			}

			>span.value {
				font-weight: bold;
				color: $blue;
			}
		}
	}

	.bid-table-container {
		padding-left: 30px;
		padding-right: 30px;
	}
}

a.modify-data {
	&::after {
		@include icomoon-icon-style();
		content: $hi-edit-infos;
		display: block;
		font-size: 24px;
		color: $black-text;
		position: absolute;
		right: 16.5px;
		top: calc(50% - 12px);
	}
}

.block-info-md-title {
	font-size: 21px;
	font-weight: bold;
	line-height: 17px;
	text-align: left;
	color: #000000;

	.value {
		color: $blue;
	}
}