.tdb-s-title {
	font-size: 35px;
	font-weight: 900;

	&.blue {
		color: $blue;
	}

	&.red {
		color: $red;
	}
}

.tdb-s-title2 {
	font-size: 26px;
	font-weight: bold;

	&.blue {
		color: $blue;
	}

	&.red {
		color: $red;
	}
}


.tdb-s-title3 {
	font-size: 21px;
	font-weight: bold;
	color: #000000;

}



.tdb-salarie {
	p {
		font-size: 16px;
		font-weight: 500;
		line-height: 23px;
	}

	.sep {
		width: 100%;
		height: 1px;
		background-color: #ccc;
	}

	.tdb-s-steps3 {
		p {
			margin-bottom: 0;
		}

		.a-step {
			background: $grey-bg;
			border-radius: 9px;
			padding: 22px;
			color: #666666;
			position: relative;
			height: 100%;

			@include media-breakpoint-down(md) {
				padding-left: 10px;
				padding-right: 10px;
			}

			@include media-breakpoint-down(sm) {
				&:not(.active) {
					display: none;
				}
			}

			.icon-step {
				font-size: 46px;
			}

			.a-step-title {
				font-size: 22px;
				font-weight: bold;

				>span.round-number {
					font-size: 18px;
					line-height: 26px;
					display: inline-block;
					width: 30px;
					height: 30px;
					border: solid 2px #666666;
					border-radius: 50%;
					text-align: center;
					margin-right: 10px;
				}

				@include media-breakpoint-down(md) {
					font-size: 19px;

					>span.round-number {
						display: block;
						margin: 0 auto;
						margin-bottom: 5px;
					}
				}

				@include media-breakpoint-down(xs) {
					>span.round-number {
						display: inline-block;
						margin-right: 10px;
					}
				}
			}

			.sep {
				height: 1px;
				background: #666666;
				width: 80px;
				margin: 14px auto;
			}

			p {
				font-size: 16px;
				font-weight: 600;
				line-height: 21px;
				padding: 0 10px;

				@include media-breakpoint-down(md) {
					font-size: 14px;
					line-height: 19px;
				}
			}

			&.active {
				background: $red;
				color: #FFF;

				p .black {
					color: #000;
				}

				.a-step-title>span.round-number {
					border-color: #FFF;
				}

				.sep {
					background: #FFF;
				}

				&:before {
					@include icomoon-icon-style();
					content: $hi-triangle-bottom;
					position: absolute;
					top: 0;
					font-size: 8px;
					left: calc(50% - 8px);
				}
			}
		}
	}

	p.tdb-s-text-offer {
		font-size: 21px;
		font-weight: 600;
		line-height: 26px;

		.big {
			font-size: 29px;
			line-height: 45px;
			color: $red;
			font-weight: 900;

			@include media-breakpoint-down(xs) {
				font-size: 26px;
				line-height: normal;

			}
		}
	}

	.tdb-s-candidature {
		.info-candidatures {
			border-radius: 10px 10px 0 0;
			background-color: $grey-bg;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 23px;
			position: relative;
			padding-bottom: 35px;

			.icon-text-data {
				.data {
					font-size: 23px;
					font-weight: bold;
				}

				.green {
					color: #7bba3e;
				}

				.orange {
					color: #f07d00;
				}

				.red {
					color: $red;
				}

				.grey {
					color: #666666;
				}
			}

			.al-border {
				border-right: 2px solid #e1e1e1;

				@include media-breakpoint-down(md) {
					border-right: 0;
					margin-bottom: 30px;
				}
			}

			.text {
				font-size: 14px;
				font-weight: bold;
				text-align: center;
				color: $blue;
				margin-bottom: 8px;
			}

			.title {
				margin-bottom: 30px;

				>span {
					vertical-align: middle;
				}

				.hi-arrow-round-right {
					margin-right: 10px;
				}

				font-size: 21px;
				color: $blue;
				font-weight: 600;

				@include media-breakpoint-down(md) {
					font-size: 18px;
				}

				@include media-breakpoint-down(xs) {
					margin-bottom: 10px;
				}

				span.big {
					font-weight: 900;
				}
			}

			.pos-icon {
				text-align: center;

				&:before {
					&.pos-high {
						content: $hi-pos-high;
					}

					color: $blue;
					@include icomoon-icon-style();
					font-size: 74px;
				}
			}

			.scoring-block {
				&.level-1 {
					>div:nth-of-type(1) {
						background-color: #7bba3e;
					}
				}

				&.level-2 {
					>div:nth-of-type(2) {
						background-color: #f07d00;
					}
				}

				&.level-3 {
					>div:nth-of-type(3) {
						background-color: $red;
					}
				}

				>div {
					margin-left: auto;
					margin-right: auto;
					width: 175px;
					height: 22px;
					margin-top: 6px;
					border-radius: 5px;
					background-color: #cccccc;

					@include media-breakpoint-down(md) {
						width: 100%;
					}
				}
			}


		}

		.steps {
			background-image: url(../img/bg-historique.png);
			background-size: contain;
			margin-left: 0;
			margin-right: 0;

			.a-step {
				padding-top: 34px;
				padding-bottom: 20px;
				position: relative;

				img {
					width: 39px;
					display: block;
					margin-left: auto;
					margin-right: auto;

					&.color {
						display: none;
					}
				}

				div.step-title {
					font-size: 11px;
					font-weight: 600;
					text-align: center;
					color: #999999;
					margin-top: 11.5px;
				}

				&::before {
					@include icomoon-icon-style();
					content: $hi-arrow-round-right;
					display: block;
					position: absolute;
					font-size: 25px;
					color: #999999;
					left: -12.5px;
					top: calc(50% - 12.5px);
				}

				&:first-of-type::before {
					content: "";
				}

				&.active {
					img.grey {
						display: none;
					}

					img.color {
						display: block;
					}

					div.step-title {
						color: $blue;
					}

					&:before {
						content: $hi-arrow-round-plain-right;
						color: $blue;
					}

					&:first-of-type::before {
						content: "";
					}

				}

				&.current {
					&:after {
						@include icomoon-icon-style();
						content: $hi-triangle-bottom;
						display: block;
						position: absolute;
						font-size: 15px;
						color: $red;
						left: calc(50% - 15px);
						top: 0px;
					}
				}

				@include media-breakpoint-down(xs) {
					&:nth-child(odd):before {
						display: none;
					}
				}
			}
		}
	}

}

.tdb-s-block-data-offer {
	border-radius: 8px;
	border: solid 1px #c9c9c9;
	background-color: #ffffff;
	text-align: center;
	padding-top: 13px;
	padding-bottom: 20px;
	background-image: url(../img/entreprise/bg-triangle-white-grey.png);
	background-size: 100% 100%;

	&.bigone {
		padding: 20px;
	}

	.text {
		font-size: 18px;
		font-weight: bold;
		color: $blue;
	}

	.data {
		font-size: 26px;
		line-height: 38px;
		font-weight: bold;
		color: $black-text;
		margin-top: 5px;
	}

	.little {
		font-size: 15px;
		vertical-align: middle;
	}

	.big {
		font-size: 26.5px;
		color: $red;
		vertical-align: middle;
	}
}

.tdb-s-block-button {
	border-radius: 9px;
	background-color: $grey-bg;
	padding: 30px;

	.status {
		font-size: 18px;
		font-weight: bold;

		&.green {
			color: #7bba3e;
		}

		&.orange {
			color: #f07d00;
		}

		&.red {
			color: $red;
		}
	}

	.title {
		font-size: 23px;
		text-align: center;
		color: #000000;
		font-weight: 900;

		@include media-breakpoint-down(md) {
			min-height: 56px;
		}

		@include media-breakpoint-down(xs) {
			min-height: initial;
		}

	}

	.title2 {
		font-size: 23px;
		font-weight: 600;
		text-align: center;
		color: #000000;
	}
}

.tdb-s-block-status {
	background-color: $grey-bg;
	padding: 20px 14px;
	padding-top: 25px;

	.title {
		font-size: 26px;
		font-weight: bold;
		line-height: 28px;
		margin-bottom: 15px;
		color: #000;

		@include media-breakpoint-down(md) {}

		@include media-breakpoint-down(xs) {}

	}

	.status {
		font-size: 21px;
		font-weight: bold;
		margin-bottom: 25px;

		&:before {
			@include icomoon-icon-style();
			font-size: 25px;
			vertical-align: middle;
			margin-right: 3px;
		}

		&.green {
			color: #7bba3e;

			&::before {
				content: $hi-check-round;
			}
		}

		&.orange {
			color: #f07d00;

			&::before {
				content: $hi-status-pending;
			}
		}

		&.red {
			color: #e41d4b;

			&::before {
				content: $hi-cross-round;
			}
		}
	}

	p {
		line-height: 23px;
		font-weight: 500;
		margin-bottom: 0;
	}

	&.full-width {
		padding-left: 30px;
		padding-right: 30px;

		@include media-breakpoint-down(md) {
			padding-left: initial;
			padding-right: initial;
		}

		p {
			text-align: left;
			display: inline-block;
		}

		.status {
			display: inline-block;
			margin-bottom: 10px;

			@include media-breakpoint-down(xs) {
				margin-top: 15px;
			}

			&::before {
				margin-left: 10px;
			}
		}
	}
}

.tdb-s-bb-text {
	font-size: 16px;
	padding: 0 10px;
}

.tdb-s-big-text {
	font-size: 26px;
	font-weight: bold;
	line-height: 31px;
	color: $blue;

	>span.highlight {
		color: $red;
		text-decoration: underline;
	}
}

.tdb-s-nurnud {
	padding-bottom: 20px;

	.radio-align-form {
		label {
			font-weight: bold;
			font-size: 16px;
		}
	}
}

.tdb-s-ok-button {
	width: 100%;
	line-height: 45px;
	background: $red;
	display: block;
	text-align: center;
	color: #fff;
	font-size: 13px;
	font-weight: bold;
	border-radius: 5px;
	text-transform: uppercase;

	&:hover {
		color: #FFF;
	}
}

.tdb-s-block-entreprise {
	margin-top: 25px;
	border-radius: 5px;
	border: solid 1px #cccccc;
	font-size: 16px;
	font-weight: 500;
	line-height: 23px;
	padding-left: 22px;
	padding-right: 22px;
	padding-top: 30px;
	padding-bottom: 10px;

	.title {
		font-weight: bold;
		color: $blue;
	}

	ul {
		margin-bottom: 20px;

		>li {
			font-weight: bold;
			margin-top: 15px;
		}
	}

	.conditions {
		list-style-type: none;
		padding-left: 0;

		>li {
			&::before {
				@include icomoon-icon-style();
				content: $hi-check-round;
				font-size: 17px;
				vertical-align: text-top;
				margin-right: 9px;
			}
		}
	}
}

.tdb-s-txt-small {
	font-size: 13px;
	font-weight: 600;
	color: #000000;
}

.tdb-s-doc-entreprise {
	font-size: 16px;

	.title {
		font-weight: bold;
		color: $blue;
	}

	.doc-list {
		margin-top: 15px;
		padding-left: 0;
		list-style-type: none;
		font-size: 14px;

		>li {
			text-decoration: none;
			margin-top: 12px;

			&:before {
				@include icomoon-icon-style();
				content: $hi-file-pdf;
				font-size: 19px;
				margin-right: 10px;
			}

			a {
				color: $black-text;
				text-decoration: underline;
			}
		}
	}


}

.tdb-s-old-candidature {
	@extend .candidature-old;
}

.tdb-s-old-candidature-sort {
	margin-top: 10px;
	margin-bottom: 5px;

	a {
		font-size: 13px;
		font-weight: 600;
		text-align: left;
		color: #666666;
		vertical-align: middle;

		&:after {
			@include icomoon-icon-style();
			content: $hi-hham;
			font-size: 14px;
			margin-left: 5px;
			vertical-align: middle;
		}
	}
}

.tdb-s-block-map {
	background-image: url(../img/tdb-carte.jpg);
	background-size: cover;
	box-shadow: -13px 0px 14px -9px rgba(179, 179, 179, 0.76) inset;

	@include media-breakpoint-down(md) {
		min-height: 500px;
	}

}

.tdb-s-block-filters {
	position: relative;
	padding: 30px 23px;

	.tool-link {
		color: #666;
		font-size: 13px;
		font-weight: 600;

		&.blue {
			color: $blue;
		}

		&.tl-cloche {
			&::before {
				display: inline-block;
				content: " ";
				height: 18px;
				width: 18px;
				background-image: url(../img/cloche.png);
				background-size: contain;
			}
		}
	}

	@include media-breakpoint-up(xs) {
		&.map-galerie {
			max-width: 540px;
		}

	}

	@include media-breakpoint-up(sm) {
		&.map-galerie {
			max-width: 540px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include media-breakpoint-up(md) {
		&.map-galerie {
			max-width: 720px;
			margin-left: auto;
			margin-right: auto;
		}
		&.galerie {
			.form-group {
				margin-bottom: 0;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&.map-galerie {
			max-width: 540px;

			
		}
	}

	@include media-breakpoint-down(sm) {
			.checkbox-align-form {
				display: initial;

				.form-check {
					margin-top: 15px;
				}
			}
	}

}

.global-form .tdb-s-checkbox-apmr .form-check {
	label.checkbox {
		font-weight: bold;
	}
}

.tdb-s-offers-text {
	font-size: 16px;
	font-weight: bold;
	line-height: 21px;
	text-align: left;
	color: $blue;
}

.tdb-s-see-more {
	.number-result {
		font-size: 13px;
		font-weight: 600;
		line-height: 49.5px;
		color: $red;
	}

	.seemore {
		font-size: 16px;
		font-weight: bold;
		line-height: 30px;
		color: $blue;
		text-decoration: underline;
	}
}