.gestion-demande-logement-detail {
	.sep-shadow {
		width: 200%;
		background-image: url(../img/entreprise/sep-shadow.png);
		height: 30px;
		margin-left: -30px;
		margin-right: -30px;
		margin-top: 10px;
		background-size: contain;
	}

	.name-and-status {
		color: $black-text;

		.en-cours {
			color: #f07d00;
		}

		.valider {
			color: #7bba3e;
		}

		.refuser {
			color: $red;
		}
	}

	.trier {
		margin-top: 10px;
		margin-bottom: 5px;

		a {
			font-size: 13px;
			font-weight: 600;
			text-align: left;
			color: #666666;
			vertical-align: middle;

			&:after {
				@include icomoon-icon-style();
				content: $hi-hham;
				font-size: 14px;
				margin-left: 5px;
				vertical-align: middle;
			}
		}
	}

	.accord-entreprise {
		border-radius: 8px;
		border: solid 2px $red;
		padding: 31px 35px;
		padding-bottom: 10px;

		.h2 {
			color: $red;
		}

		.little-title {
			font-size: 18px;
			font-weight: bold;
			color: $red;
		}

		.form-group {}
	}

	.pad-collapse {
		padding: 19px;

		@include media-breakpoint-down(sm) {
			padding: 5px;
		}
	}









	.candidature-ec {
		border-radius: 10px;
		background-color: $grey-bg;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 23px;
		position: relative;
		padding-bottom: 35px;

		.icon-text-data {
			.data {
				font-size: 23px;
				font-weight: bold;
			}

			.green {
				color: #7bba3e;
			}

			.orange {
				color: #f07d00;
			}

			.red {
				color: $red;
			}
		}

		.al-border {
			border-right: 2px solid #e1e1e1;

			@include media-breakpoint-down(md) {
				border-right: 0;
				margin-bottom: 30px;
			}
		}

		.text {
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			color: $blue;
			margin-bottom: 8px;
		}

		.title {
			>span {
				vertical-align: middle;
			}

			.hi-arrow-round-right {
				margin-right: 10px;
			}

			font-size: 21px;
			color: $blue;
			font-weight: 600;

			@include media-breakpoint-down(md) {
				font-size: 18px;
			}

			span.big {
				font-weight: 900;
			}
		}

		.pos-icon {
			text-align: center;

			&:before {
				&.pos-high {
					content: $hi-pos-high;
				}

				color: $blue;
				@include icomoon-icon-style();
				font-size: 74px;
			}
		}

		.scoring-block {
			&.level-1 {
				>div:nth-of-type(1) {
					background-color: #7bba3e;
				}
			}

			&.level-2 {
				>div:nth-of-type(2) {
					background-color: #f07d00;
				}
			}

			&.level-3 {
				>div:nth-of-type(3) {
					background-color: $red;
				}
			}

			>div {
				margin-left: auto;
				margin-right: auto;
				width: 175px;
				height: 22px;
				margin-top: 6px;
				border-radius: 5px;
				background-color: #cccccc;

				@include media-breakpoint-down(md) {
					width: 100%;
				}
			}
		}
	}
}



.candidature-old {
	border-radius: 10px;
	background-color: $grey-bg;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 23px;
	position: relative;
	color: #666666;

	@include media-breakpoint-down(xs) {
		padding-left: 12px;
		padding-right: 12px;
		padding-top: 15px;
	}

	.title {
		.hi-arrow-round-right {
			margin-right: 10px;

			@include media-breakpoint-down(xs) {
				margin-right: 5px;
				margin-top: 1px;
			}
		}

		>div {
			display: inline-block;
			vertical-align: top;

			@include media-breakpoint-down(xs) {
				&:not([class^="hi-"]) {
					width: calc(100% - 32px);
				}
			}

		}

		font-size: 21px;

		@include media-breakpoint-down(md) {
			font-size: 17px;
		}

		font-weight: 600;

		span.big {
			font-weight: 900;
		}

		span.little {
			font-size: 18px;

			@include media-breakpoint-down(md) {
				font-size: 14px;
			}
		}
	}
}

.demande-logement-recap,
.gestion-demande-logement-detail,
.demande-logement-demande-pj {
	.bb-title {
		font-weight: 900;
		font-size: 35px;
		text-align: left;
		color: #323232;
		margin-bottom: 25px;

		@include media-breakpoint-down(md) {
			font-size: 24px;
		}
	}

	.pad-collapse {
		padding: 19px;
	}

	.name-profile {
		font-size: 21px;
		font-weight: bold;

		@include media-breakpoint-down(md) {
			font-size: 17px;
		}

		&::before {
			vertical-align: middle;
		}
	}

	.file-box {
		border-radius: 10px;
		background-color: $grey-bg;
		padding-left: 20px;
		padding-right: 20px;
		position: relative;
		padding-bottom: 16px;

		.title {
			padding-top: 21px;
			padding-bottom: 21px;
			font-size: 16px;

			>span {
				font-weight: bold;
			}
		}

		.toolbox {
			position: absolute;
			right: 20px;
			>a {
				color: $black-text;
			}

			.hi-upload {
				font-size: 22px;
			}
			.hi-refresh {
				font-size: 20px;
			}

			.hi-eye {
				font-size: 17px;
				margin-left: 18.5px;
			}

			.error {
				color: $red;
			}

			@include media-breakpoint-down(md) {
				display: block !important;
				position: initial;
				text-align: right;
				margin-top: 7px;

				.hi-upload {
					font-size: 15px;
				}

				.hi-eye {
					font-size: 12px;
					margin-left: 8px;
				}
			}
		}

		.sep {
			height: 1px;
			width: 100%;
			background-color: #e1e1e1;
			margin-bottom: 8px;
		}

		.hi-edit {
			font-size: 18px;
		}

		.a-file {
			padding-top: 8px;
			padding-bottom: 8px;

			>div {
				display: inline-block;
				font-weight: 500;
				vertical-align: middle;
			}

			.icon-state {
				width: 27px;
				margin-right: 14px;
				text-align: center;

				@include media-breakpoint-down(md) {
					margin-right: 0;
					margin-bottom: 7px;
				}

				&:before {
					@include icomoon-icon-style();
					font-size: 23px;

					@include media-breakpoint-down(md) {
						font-size: 19px;

					}
				}

				&.is-ok:before {
					content: $hi-check-round;
					color: #7bba3e;

				}

				&.is-ko:before {
					content: $hi-danger;
					color: $red;
				}

				&.is-missing:before {
					content: $hi-cross-round;
					color: $red;
				}

			}

			.icon-file {
				margin-right: 7px;

				@include media-breakpoint-down(md) {
					margin-bottom: 7px;
				}
			}

			.icon-file:before {
				font-size: 26px;
				@include icomoon-icon-style();
				content: $hi-file;

				@include media-breakpoint-down(md) {
					font-size: 17px;
				}
			}

			.message {
				color: $red;
			}
		}
	}
}

