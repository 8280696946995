$icomoon-font-family: "hermes-icon" !default;
$icomoon-font-path: "../../assets/fonts/#{$icomoon-font-family}" !default;

$hi-checkbox-check: "\e91d";
$hi-dispo-logement: "\e912";
$hi-generate-pdf: "\e91b";
$hi-chauffage: "\e901";
$hi-jardin: "\e902";
$hi-parking: "\e903";
$hi-cuisine: "\e904";
$hi-cave: "\e905";
$hi-gardien: "\e906";
$hi-pmr: "\e907";
$hi-etage: "\e908";
$hi-pieces: "\e909";
$hi-sdb: "\e90a";
$hi-dlpdf: "\e90b";
$hi-people: "\e90c";
$hi-cdc: "\e900";
$hi-fl-corner-left: "\25e4";
$hi-upload: "\e93a";
$hi-house-hand: "\e939";
$hi-pause-round: "\e938";
$hi-error-round: "\e937";
$hi-line-star: "\e936";
$hi-arrow-round-plain-top: "\e932";
$hi-arrow-round-plain-right: "\e933";
$hi-arrow-round-plain-bottom: "\e934";
$hi-arrow-round-plain-left: "\e935";
$hi-file-pdf: "\e931";
$hi-status-pending: "\e930";
$hi-tdb-s-offres: "\e92d";
$hi-tdb-s-situation: "\e92e";
$hi-tdb-s-candidatures: "\e92f";
$hi-edit-infos: "\e92c";
$hi-pos-low: "\e926";
$hi-pos-med: "\e92a";
$hi-pos-high: "\e92b";
$hi-eye: "\e927";
$hi-file: "\e928";
$hi-danger: "\e929";
$hi-chevron-big-bottom: "\e922";
$hi-chevron-big-left: "\e923";
$hi-chevron-big-top: "\e924";
$hi-chevron-big-right: "\e925";
$hi-plus-round: "\e921";
$hi-trash: "\e920";
$hi-download: "\e91f";
$hi-mini-selector: "\e91e";
$hi-cross-round: "\e91c";
$hi-hham: "\e919";
$hi-hmc: "\e91a";
$hi-email: "\e916";
$hi-triangle-left: "\2b82";
$hi-triangle-bottom: "\e917";
$hi-triangle-top: "\e918";
$hi-triangle-right: "\2b81";
$hi-chat: "\e913";
$hi-edit: "\e914";
$hi-phone: "\e915";
$hi-check-round: "\e911";
$hi-chevron-top: "\e90d";
$hi-chevron-left: "\e90e";
$hi-chevron-bottom: "\e90f";
$hi-chevron-right: "\e910";
$hi-arrow-round-top: "\2b06";
$hi-arrow-round-right: "\2b80";
$hi-arrow-round-bottom: "\2b07";
$hi-arrow-round-left: "\2b05";






@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?tfjy50') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?tfjy50') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?tfjy50##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}


@mixin icomoon-icon-style {
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="hi-"]:before, [class*=" hi-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  @include icomoon-icon-style();
}

.hi-checkbox-check {
	&:before {
		content: $hi-checkbox-check;
	}
}

.hi-dispo-logement {
	&:before {
		content: $hi-dispo-logement;
	}
}

.hi-generate-pdf {
	&:before {
		content: $hi-generate-pdf;
	}
}

.hi-chauffage {
	&:before {
		content: $hi-chauffage;
	}
}

.hi-jardin {
	&:before {
		content: $hi-jardin;
	}
}

.hi-parking {
	&:before {
		content: $hi-parking;
	}
}

.hi-cuisine {
	&:before {
		content: $hi-cuisine;
	}
}

.hi-cave {
	&:before {
		content: $hi-cave;
	}
}

.hi-gardien {
	&:before {
		content: $hi-gardien;
	}
}

.hi-pmr {
	&:before {
		content: $hi-pmr;
	}
}

.hi-etage {
	&:before {
		content: $hi-etage;
	}
}

.hi-pieces {
	&:before {
		content: $hi-pieces;
	}
}

.hi-sdb {
	&:before {
		content: $hi-sdb;
	}
}

.hi-dlpdf {
	&:before {
		content: $hi-dlpdf;
	}
}

.hi-people {
	&:before {
		content: $hi-people;
	}
}

.hi-cdc {
	&:before {
		content: $hi-cdc;
	}
}

.hi-fl-corner-left {
	&:before {
		content: $hi-fl-corner-left;
	}
}

.hi-upload {
	&:before {
		content: $hi-upload;
	}
}

.hi-house-hand {
	&:before {
		content: $hi-house-hand;
	}
}

.hi-pause-round {
	&:before {
		content: $hi-pause-round;
	}
}

.hi-error-round {
	&:before {
		content: $hi-error-round;
	}
}

.hi-line-star {
	&:before {
		content: $hi-line-star;
	}
}

.hi-arrow-round-plain-top {
	&:before {
		content: $hi-arrow-round-plain-top;
	}
}

.hi-arrow-round-plain-right {
	&:before {
		content: $hi-arrow-round-plain-right;
	}
}

.hi-arrow-round-plain-bottom {
	&:before {
		content: $hi-arrow-round-plain-bottom;
	}
}

.hi-arrow-round-plain-left {
	&:before {
		content: $hi-arrow-round-plain-left;
	}
}

.hi-file-pdf {
	&:before {
		content: $hi-file-pdf;
	}
}

.hi-status-pending {
	&:before {
		content: $hi-status-pending;
	}
}

.hi-tdb-s-offres {
	&:before {
		content: $hi-tdb-s-offres;
	}
}

.hi-tdb-s-situation {
	&:before {
		content: $hi-tdb-s-situation;
	}
}

.hi-tdb-s-candidatures {
	&:before {
		content: $hi-tdb-s-candidatures;
	}
}

.hi-edit-infos {
	&:before {
		content: $hi-edit-infos;
	}
}

.hi-pos-low {
	&:before {
		content: $hi-pos-low;
	}
}

.hi-pos-med {
	&:before {
		content: $hi-pos-med;
	}
}

.hi-pos-high {
	&:before {
		content: $hi-pos-high;
	}
}

.hi-eye {
	&:before {
		content: $hi-eye;
	}
}

.hi-file {
	&:before {
		content: $hi-file;
	}
}

.hi-danger {
	&:before {
		content: $hi-danger;
	}
}

.hi-chevron-big-bottom {
	&:before {
		content: $hi-chevron-big-bottom;
	}
}

.hi-chevron-big-left {
	&:before {
		content: $hi-chevron-big-left;
	}
}

.hi-chevron-big-top {
	&:before {
		content: $hi-chevron-big-top;
	}
}

.hi-chevron-big-right {
	&:before {
		content: $hi-chevron-big-right;
	}
}

.hi-plus-round {
	&:before {
		content: $hi-plus-round;
	}
}

.hi-trash {
	&:before {
		content: $hi-trash;
	}
}

.hi-download {
	&:before {
		content: $hi-download;
	}
}

.hi-mini-selector {
	&:before {
		content: $hi-mini-selector;
	}
}

.hi-cross-round {
	&:before {
		content: $hi-cross-round;
	}
}

.hi-hham {
	&:before {
		content: $hi-hham;
	}
}

.hi-hmc {
	&:before {
		content: $hi-hmc;
	}
}

.hi-email {
	&:before {
		content: $hi-email;
	}
}

.hi-triangle-left {
	&:before {
		content: $hi-triangle-left;
	}
}

.hi-triangle-bottom {
	&:before {
		content: $hi-triangle-bottom;
	}
}

.hi-triangle-top {
	&:before {
		content: $hi-triangle-top;
	}
}

.hi-triangle-right {
	&:before {
		content: $hi-triangle-right;
	}
}

.hi-chat {
	&:before {
		content: $hi-chat;
	}
}

.hi-edit {
	&:before {
		content: $hi-edit;
	}
}

.hi-phone {
	&:before {
		content: $hi-phone;
	}
}

.hi-check-round {
	&:before {
		content: $hi-check-round;
	}
}

.hi-chevron-top {
	&:before {
		content: $hi-chevron-top;
	}
}

.hi-chevron-left {
	&:before {
		content: $hi-chevron-left;
	}
}

.hi-chevron-bottom {
	&:before {
		content: $hi-chevron-bottom;
	}
}

.hi-chevron-right {
	&:before {
		content: $hi-chevron-right;
	}
}

.hi-arrow-round-top {
	&:before {
		content: $hi-arrow-round-top;
	}
}

.hi-arrow-round-right {
	&:before {
		content: $hi-arrow-round-right;
	}
}

.hi-arrow-round-bottom {
	&:before {
		content: $hi-arrow-round-bottom;
	}
}

.hi-arrow-round-left {
	&:before {
		content: $hi-arrow-round-left;
	}
}
