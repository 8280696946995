.gestion-offre-actions {
	width: 100%;
	text-align: center;
	margin-top: 15px;
	.info-date {
		background-size: 100% 100%;
		color: #FFF;
		font-weight: bold;
		border-radius: 5px;
		background-image: url('../img/entreprise/bg-info-exclu.png');
		padding: 7px 15px;
		margin-top: 5px;
	}

	.btn-en-gop {
		display: block;
		padding: 6px 15px;
		font-weight: bold;
		border-radius: 5px;
		background-image: url('../img/entreprise/bg-triangle-white-grey.png');
		background-size: 100% 100%;
		border: solid 1px #c9c9c9;
		color: $blue;
		margin-top: 5px;
		position: relative;
		> span {
			color: $red;
		}
		&:after	{
			@include icomoon-icon-style();
			content: $hi-chevron-big-right;
			position: absolute;
			font-size: 11px;
			right: 8.5px;
			top: calc(50% - 4.25px);
		}
	}
}