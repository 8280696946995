header {
	margin-bottom: 20px;
	background: $grey-bg;

	nav.navbar {
		padding: 22px 0;
		min-height: 100px;

		@include media-breakpoint-down(lg) {
			min-height: auto;
			padding: 10px 0;
		}
	}
	.navbar-brand {
		margin-right: 0;
		span.brand-name {
			font-size: 26px;
			line-height: 17px;
			font-weight: bold;
			color: $blue;
			margin-left: 38.5px;
			vertical-align: middle;

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		img {
			max-height: 90px;
			    max-width: 290px;
			@include media-breakpoint-down(lg) {
				max-height: 40px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.navbar-expand-lg .navbar-collapse {
			justify-content: flex-end;
		}

		#navbarToggler a:not(.dropdown-item) {
			margin-left: 13.5px;
		}
	}

	@include media-breakpoint-down(md) {
		#navbarToggler a {
			margin-top: 10px;
			border-radius: 0;
		}
		.header-lnk.inside-menu {
			display: none !important;
		}
		.content-nav {
			text-align: center;
			margin-top: 25px;
			margin-bottom: 15px;
			a {
				margin: 5px;
			}
		}
	}

	

	.dropdown-header-nav {
		display: inline-block;
		position: relative;
		width: inherit;
		height: inherit;
		.dropdown-toggle::after {
			display: none;
		}
		.dropdown-menu {
			min-width: 125px;
			left: 13.5px;
			border: none;
			box-shadow: 0 0 35px 0 rgba($color: #000000, $alpha: 0.2);
			.dropdown-item {
				padding-left: 10px;
				padding-right: 10px;
				.notification {
					text-align: center;
					font-size: 12px;
					line-height: 17px;
					width: 17px;
					height: 17px;
					display: inline-block;
					color: #fff;
					border-radius: 50px;
					background: $red;

				}
				&:not(.big):hover {
					color: $red;
					text-decoration: underline;
					background: transparent;
				}
			}
			font-size: 13px;
			top: 27px;
			color: $blue;
		}
	}

	.header-lnk {
		font-size: 14px;
		font-weight: bold;
		color: $blue;

		img {
			display: inline-block;
			height: 26px;
			margin-right: 11px;
		}
	}

	.header-btn {
		font-size: 13px;
		padding: 8px 12px;
		border: solid 2px $blue;
		border-radius: 5px;
		font-weight: bold;
		color: $blue;

		&:before {
			vertical-align: middle;
			font-size: 16px;
			margin-right: 7px;
		}

		&.my-account {
			color: #FFF;
			background-color: $blue;
			border-color: $blue;
		}

		&.acces-rapide {
			color: $blue;
			background-color: #FFF;
			border-color: $blue;

			&:before {
				color: $red;
			}
		}
	}

}

header.entreprise,
header.bailleur,
header.salarie {
	a.acces-rapide-toggler-mobile {
		font-size: 35px;
		line-height: 35px;
		color: $blue;
		vertical-align: middle;
		&::before {
			vertical-align: middle;
		}
		
	}
	.header-logo-mobile {
		display: inline-block;
		vertical-align: middle;
		max-width: 300px;
		width: calc(100% - 48px);
		margin-left:8px;
		img {
			height: 100%;
		}
	}
	.right-col-menu-mobile {
		a {
			font-size: 25px;
			color: $red;
			vertical-align: middle;
			line-height: 25px;
			width: 30px;
			height: 30px;
			text-align: center;
			
			&:not(:first-of-type) {
				margin-left: 8px;
			}
			&::before {
				vertical-align: middle;
			}
		}
		img {
			height: 25px;
			vertical-align: middle;
		}
	}
}

header.salarie .header-logo-mobile img {
	height: unset;
	max-width: 100%;
}



.header-desktop {
	@include media-breakpoint-up(lg) {
		display: flex;
	}
	@include media-breakpoint-down(md) {
		display: none;
	}
}
.header-mobile {
	@include media-breakpoint-up(lg) {
		display: none;
	}
	@include media-breakpoint-down(md) {
		display: flex;
	}
	.navbar-nav {
		padding-top: 30px;
	}
}

header {
	.dropdown-item,
	.navbar-nav .nav-item .nav-link {
		font-size: 13px;
		font-weight: bold;
		text-align: left;
		padding-left: 14px;
		padding-right: 14px;
		padding-top: 7px;
		padding-bottom: 7px;
		color: $black-text;
		min-width: unset;
		&.highlight {
			color: $red;
			text-decoration: underline;

		}

		&.big {
			font-size: 20px;
		}
	}
	.navbar-nav .nav-item .nav-link {
		font-size: 15px;
		.notification {
			text-align: center;
			font-size: 12px;
			line-height: 17px;
			width: 17px;
			height: 17px;
			display: inline-block;
			color: #fff;
			border-radius: 50px;
			background: $red;

		}
	}
}

header.salarie nav.navbar {
	padding: 10px 0;
}
@include media-breakpoint-down(sm) {
.home.deconnecte header {
	.hi-hmc {
		display: inline-block;
		background: $red;
		border-radius: 50px;
		color: #FFF;
		font-size: 32px;
		position: relative;
		&:before {
			position: absolute;
			left: -1px;
			top: -1px;
		}
	}
}}