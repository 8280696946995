.login-entreprise {
	header {
		margin-bottom: 87px;
		.header-login {
			padding-top: 25px;
			padding-bottom: 10px;
			span.h2 {
				font-size: 24px;
				@include media-breakpoint-down(md) {
					font-size: 15px;

				}
				@include media-breakpoint-down(sm) {
					font-size: 19px;
					
				}
			}
			.header-lnk.inside-menu {
				display: block !important;
				@include media-breakpoint-down(sm) {
					margin-top: 15px;
				}
			}
			.logo {
				img {
					width: 100%;
					max-width: 260px;
					margin-bottom: 20px;
					@include media-breakpoint-down(md) {
						margin-bottom: 15px;
					}
				}
			}
		}
		
	}
	h1 {
		margin-bottom: 38px;
	}
	.block-red {
		background-color: $red;
		margin-top: 78px;
		padding-top: 57px;
		padding-bottom: 57px;
		color: #ffffff;
		background-image: url(../img/entreprise/bg-red-login.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto 100%;
		margin-bottom: 60px;
		.title {
			font-size: 29px;
			text-align: center;
			font-weight: 900;
			margin-bottom: 22.5px;
		}
		.text {
			font-size: 16px;
			font-weight: bold;
			line-height: 21px;
			text-align: center;
		}
	}
		.lost-password a,
		.premiere-visite a {
			display: block;
			font-size: 13px;
			font-weight: 500;
			color: $grey-text;
			text-decoration: underline;
		}
}

.login-salarie {
	@extend .login-entreprise;
}

.login-bailleur {
	@extend .login-entreprise;
}