body.fiche-logement {
    .slick-arrow {
        top: 45%;
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
        &:before {
            color: #e1e1e1;
            font-size: 65px;
            font-family: '#{$icomoon-font-family}' !important;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
        }
    }
    .slick-prev {
        left: -85px;
        
        &:before {
            content: $hi-chevron-left;
        }
    }
    .slick-next {
        right: -85px;

        &:before {
            content: $hi-chevron-right;
        }
    }
    .highlights {
        margin-top: 28.5px;
        margin-bottom: 20px;

        >div {
            border-right: 2px solid #e1e1e1;

            &:last-child {
                border-right: 0;
            }

            &.jauge {

                span.highlight-data {
                    line-height: 0px;
                }
            }

            img {
                height: 34.5px;
                display: block;
                margin: 0 auto;
                margin-bottom: 6px;
            }

            span {
                font-weight: bold;
                color: $blue;

                span.highlight-data {
                    font-size: 18px;
                    color: $red;
                }

                p.info-small {
                    padding: 0 15px;
                    margin-top: 3px;
                    margin-bottom: 5px;
                    font-size: 11px;
                    line-height: 12.8px;
                    color: $grey-text;
                }
            }
		}
    }

    .main-image {
        position: relative;
        margin-bottom: 25px;
        .triangle::before {
            position: absolute;
            font-size: 70px;
            z-index: 9;
            top: -0.6px;
            left: -0.6px;
            text-shadow: 0px 19px 0px #FFF,
                19px 0px 0px #FFF;
        }

        .btn-tools {
            position: absolute;
            right: 12px;
            top: 12px;
            z-index: 9;

            >a {
                border-radius: 5px;
                display: inline-block;
                width: 36px;
                height: 36px;
                background-color: #fff;
                vertical-align: top;

                &.cdc {
                    background: #FFF;
                    color: #a7a9ac;
                    text-align: center;
                    line-height: 36px;
                    font-size: 15px;

                    &:before {
                        content: $hi-cdc;
                        font-family: $icomoon-font-family;


                    }

                    &.active {
                        background: $red;
                        color: #FFF;
                    }
                }

                >img {
                    width: 100%;
                }
            }
        }

        .main-image-slider {
            .slick-arrow {
                position: absolute;
            }

            .aslide {
                border-radius: 10px;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }
        }

        .main-image-slider-nav {
            margin-top: 8px;
            margin-left: -7px;
            margin-right: -7px;

            .aslide {
                padding-left: 7px;
                padding-right: 7px;

                overflow: hidden;

                img {
                    width: 100%;
                    border-radius: 5px;
                }
            }
        }

    }

    .logement-main-info {
        .left-main-info {
            .price {
                font-weight: 900;
                font-size: 27px;
                color: $red;
            }

            .title {
                font-size: 21px;
            }
            .line-info {
                font-weight: 900;
                font-size: 22px;
                color: $red;

                span {
                    display: inline-block;
                }

                div.sep-info {
                    display: inline-block;
                    color: $black-text;
                    margin: 0 10px;
                }
            }
        }

        .right-main-info , .city {
            color: $red;
            font-weight: 700;
            font-size: 21px;
        }
    }
    .description-info {
        .dlpdf {
            text-decoration: underline;
            font-weight: bold;
            font-size: 11px;
            color: $black-text;
            &:before {
                display: inline-block;
                font-size: 25px;
                vertical-align: middle;
                margin-right: 9px;
            }
        }
    }
 
    
    .features-info {
        font-size: 12px;
        color: $black-text;
        font-weight: bold;
        font-size: 13px;
        ul {
            padding-left: 0;
            li {
                list-style-type: none;
                margin-top: 10px;
                margin-bottom: 10px;
                &::before {
                    width: 22px;
                    display: inline-block;
                    text-align: center;
                    margin-right: 7px;
                    font-size: 16px;
                    vertical-align: middle;
                }
            }
        }
        .conso span {
            margin-top: 10px;
            margin-bottom: 8px;
            display: block;
        }
    }
}

body.fiche-logement.entreprise-priv {
	.highlights {
		>div {
			img {
				height: 45px;
			}
		}
		.button-pers {
			padding-left: 30px; padding-right: 30px;
			>a {
				height: 100%;
				display: block;
				border: solid 1px #c9c9c9;
				border-radius: 8px;
				padding: 12px;
				background-image: url(../img/entreprise/bg-triangle-white-grey.png);
				background-size: 100% 100%;
				position: relative;
				&::before {
					position: absolute;
					right: 10px;
					top: calc(50% - 8.5px);
					font-size: 17px;
					color: $blue;
				}
				img {
					height: 26.5px;
					margin-bottom: 8px;
				}
				span {
					line-height: 19px;
					span.highlight-data {
						font-size: 19px;
					}
				}
			}
		}
	}
}

.fiche-logement.entreprise-priv header {background-color: #fff;}

@include media-breakpoint-down(md) {
    body.fiche-logement {
            .slick-arrow {
                z-index: 9;
                top: 45%;
                &:before {
                    font-size: 30px;
                }
            }
            .slick-prev {
                left: 5px;
                &:before {
                    content: $hi-arrow-round-left;
                }
            }
            .slick-next {
                right: 5px;
                &:before {
                    content: $hi-arrow-round-right;
                }
            }
            .logement-main-info {
                .left-main-info {
                    .price {
                        font-size: 24px;
                    }

                    .title,
                    .city {
                        font-size: 19px;
                    }
                    .line-info {
                        font-size: 19px;
                    }
                }

                .right-main-info,
                .city {
                    color: $red;
                    font-weight: 700;
                    font-size: 21px;
                }
            }
    }
}

@include media-breakpoint-down(sm) {
    body.fiche-logement {
        .slick-arrow {
            z-index: 9;
            top: 45%;

            &:before {
                font-size: 25px;
            }
        }
        .features-info .conso {
            font-size: 11px;
        }
        .highlights {
            >div {
                border: 0;
                &:first-child {
                    border-right: 2px solid #e1e1e1;
                }
                &.jauge {
                    margin-top: 20px;
                }
            }
        }
    }
}

body.fiche-logement.jauge-rouge {
    .main-image .triangle::before {
        color: $red;
    }
    .highlights>div.jauge {
        img.jauge_rouge {
            display: block;
        }
        img.jauge_vert,
        img.jauge_orange {
            display: none;
        }
        span.highlight-data {
            color: $red;
        }
    }
}

body.fiche-logement.jauge-orange {
    .main-image .triangle::before {
        color: #f07d00;
    }
    .highlights>div.jauge {
        img.jauge_orange {
            display: block;
        }
        img.jauge_vert,
        img.jauge_rouge {
            display: none;
        }
        span.highlight-data {
            color: #f07d00;
        }
    }
}

body.fiche-logement.jauge-vert {
    .main-image .triangle::before {
        color: #7bba3e;
    }
    .highlights>div.jauge {
        img.jauge_vert {
            display: block;
        }
        img.jauge_rouge,
        img.jauge_orange {
            display: none;
        }
        span.highlight-data {
            color: #7bba3e;
        }
    }
}